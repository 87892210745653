import React from 'react';
import { Container, Text } from '@mantine/core';
import Contact from '../components/Contact';
import sourceVideo from '../assets/sourceVideo.mp4';

const Home: React.FC = () => {
  return (
    <>
      <Container
        style={{
          width: '80%',
          margin: '0 auto',
          marginTop: '50px',
          color: 'black',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          fontFamily: 'Roboto, sans-serif',
          position: 'relative', // Ensure stacking context
          zIndex: 1, // Default stacking level
        }}
      >
          <video
            src={sourceVideo}
            controls
            loop
            muted
            style={{
              objectFit: 'contain',
              marginTop: '10px',
              borderRadius: '10px',
              maxWidth: '75%',
              zIndex: -1, // Ensure it's behind text, if needed
            }}
          >
            Your browser does not support the video tag.
          </video>
        <Text
          style={{
            marginTop: '30px',
            fontSize: '1rem',
            fontWeight: 'normal',
            zIndex: 2, // Ensure it's above the video
            position: 'relative',
          }}
        >
          For any queries, please find contact details below
        </Text>
      </Container>
      <Contact />
    </>
  );
};

export default Home;
