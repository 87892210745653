import React from 'react';
import { Container, Typography, Grid, Card, CardContent, Button } from '@mui/material';

const documents = [
  {
    id: 1,
    title: 'ISO Certificate - Donghexin',
    description: 'ISO 9001:2015 certification for Donghexin New Materials Industry.',
    file: '/assets/documents/无锡东和欣iso证书.pdf',
  },
  {
    id: 2,
    title: 'RoHS Compliance Test Report',
    description: 'RoHS compliance report detailing material safety standards.',
    file: '/assets/documents/BIAN- ROHS测试（英文）-副本.pdf',
  },
  {
    id: 3,
    title: 'Donghexin Company Brochure',
    description: 'Comprehensive overview of Donghexin’s operations and capabilities.',
    file: '/assets/documents/东和欣宣传中英文版-V2-20240516(2).pdf',
  },

  {
    id: 4, 
    title: 'Laminated Steel Sheets',
    description: 'Detailed product information and application for laminated steel sheets.',
    file: '/assets/documents/240424金属复膜包装宣传册.pdf',
  },

  {
    id: 5, 
    title: 'Coated Steel Sheets',
    description: 'Detailed product information and applications for coated steel sheets.', 
    file: '/assets/documents/coatedSteelSheet.pdf',
  },
  {
    id: 6, 
    title: 'Black Plate Tinplate Sheets',
    description:'Detailed product information and applications for black plate tinplate sheets',
    file: '/assets/documents/DHX TINPLATE CATALOGUE -240424.pdf',
  }
];

const Trading: React.FC = () => {
  return (
    <Container
      style={{
        marginTop: '50px',
        padding: '20px',
        textAlign: 'center',
        fontFamily: 'Roboto, sans-serif',
      }}
    >
      <Typography variant="h4" gutterBottom style={{ marginBottom: '20px', fontWeight: 'bold' }}>
        Trading Resources
      </Typography>
      <Typography variant="subtitle1" style={{ marginBottom: '40px', color: '#555' }}>
        Download the latest certifications, reports, and brochures related to our trading operations.
      </Typography>
      <Grid container spacing={4}>
        {documents.map((doc) => (
          <Grid item xs={12} sm={6} md={4} key={doc.id}>
            <Card style={{ height: '100%' }}>
              <CardContent>
                <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                  {doc.title}
                </Typography>
                <Typography variant="body2" style={{ marginBottom: '20px', color: '#777' }}>
                  {doc.description}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  href={doc.file}
                  download
                  style={{ textTransform: 'none' }}
                >
                  Download
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Trading;
